import { mapToArray } from '../..';
import { PaymentMethod } from '../../../types/types';
import { SlotService, SlotServices } from '../../state/types';

/** *
 * This function checks wether the policy warning should be shown
 * This warninf should only appear for services with cancellation fees and which should
 * not normally go through checkout
 * In order to display the warning, the service must not be in the cart, the upsell plugin must not be installed
 * and all of the services should have payment method which is not online payment and have either cancellation fee or save credit card policy
 */

export const shouldDisplayPolicyWarning = ({
  slotServices,
  isServiceInCart,
  isUpsellPluginInstalled,
}: {
  slotServices: SlotServices;
  isServiceInCart: boolean;
  isUpsellPluginInstalled: boolean;
}) => {
  const services = mapToArray<SlotService>(slotServices);
  return (
    !isServiceInCart &&
    !isUpsellPluginInstalled &&
    services.every(
      (service) =>
        service.selectedPaymentType !== PaymentMethod.ONLINE &&
        (service.service.hasCancellationFee ||
          service.service.hasSaveCreditCardPolicy),
    )
  );
};
