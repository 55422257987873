import React from 'react';
import Text, { TextType } from '../../Text/Text';
import { CollapseFormDataHooks } from './dataHooks';
import {
  SectionNotification,
  SectionNotificationSize,
  SectionNotificationType,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { st, classes } from './CollapseForm.st.css';
import { ServiceData } from '../../../../utils/state/types';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { useFormActions } from '../../Hooks/useFormActions';

export interface CollapseFormProps {
  firstName: string;
  lastName?: string;
  email: string;
  phone: string;
  isServiceInCart?: boolean;
  serviceData: ServiceData;
}

const CollapseForm: React.FC<CollapseFormProps> = ({
  firstName,
  lastName,
  email,
  phone,
  serviceData,
  isServiceInCart,
}) => {
  const { experiments } = useExperiments();
  const { isRTL } = useEnvironment();
  const { onEditCollapseData } = useFormActions();
  const { t } = useTranslation();

  const isBookOnBehalfEnabled = experiments.enabled(
    ExperimentsConsts.BookOnBehalf,
  );

  const formFieldLables = serviceData?.form
    ? {
        firstName: serviceData.form?.firstName?.label!,
        lastName: serviceData.form?.lastName?.label,
        email: serviceData.form?.email?.label!,
        phone: serviceData.form?.phone?.label!,
      }
    : undefined;

  const firstNameLabel = formFieldLables?.firstName;
  const lastNameLabel = formFieldLables?.lastName;
  let name: string = '';

  if (!lastNameLabel && lastName) {
    name = `${firstName} ${lastName}`.trim();
  }

  const emailLabel = formFieldLables?.email;
  const phoneLabel = formFieldLables?.phone;

  return (
    <div className={st(classes.root, { rtl: isRTL })}>
      {isBookOnBehalfEnabled && isServiceInCart && (
        <SectionNotification
          className={classes.sectionNotification}
          type={SectionNotificationType.default}
          size={SectionNotificationSize.compact}
        >
          <SectionNotification.Text>
            {t('app.booking-details-collapse-form-your-details-message')}
          </SectionNotification.Text>
        </SectionNotification>
      )}
      {!isServiceInCart && (
        <TextButton
          className={classes.textButton}
          priority={TextButtonPriority.link}
          onClick={() => onEditCollapseData()}
          data-hook={CollapseFormDataHooks.EDIT_BUTTON}
        >
          {t('app.bookings-form.collapse-form.edit')}
        </TextButton>
      )}

      <div>
        <Text
          data-hook={CollapseFormDataHooks.FIRST_NAME}
          type={TextType.Secondary}
        >
          {!isBookOnBehalfEnabled ? `${firstNameLabel}: ` : ''}
          {name ? name : firstName}
        </Text>
      </div>
      {lastNameLabel && lastName && (
        <div>
          <Text
            data-hook={CollapseFormDataHooks.LAST_NAME}
            type={TextType.Secondary}
          >
            {!isBookOnBehalfEnabled ? `${lastNameLabel}: ` : ''}
            {lastName}
          </Text>
        </div>
      )}
      <div>
        <Text data-hook={CollapseFormDataHooks.EMAIL} type={TextType.Secondary}>
          {!isBookOnBehalfEnabled ? `${emailLabel}: ` : ''}
          {email}
        </Text>
      </div>
      {phone && (
        <div>
          <Text
            data-hook={CollapseFormDataHooks.PHONE}
            type={TextType.Secondary}
          >
            {!isBookOnBehalfEnabled ? `${phoneLabel}: ` : ''}
            {phone}
          </Text>
        </div>
      )}
      {!isBookOnBehalfEnabled && (
        <SectionNotification
          className={classes.sectionNotification}
          type={SectionNotificationType.default}
          size={SectionNotificationSize.compact}
          data-hook=""
        >
          <SectionNotification.Text>
            {t('app.booking-details-collapse-form-your-details-message')}
          </SectionNotification.Text>
        </SectionNotification>
      )}
    </div>
  );
};

export default CollapseForm;
