import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';
import TextComponent, { TextType } from '../../Text/Text';
import { TagName } from '../../../../utils/tagName.const';
import { classes } from './SmsNotification.st.css';
import { SmsNotificationDataHooks } from './datahooks';

const SmsNotification: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root} data-hook={SmsNotificationDataHooks.main}>
      <TextComponent
        type={TextType.Secondary}
        tagName={TagName.Paragraph}
        data-hook={SmsNotificationDataHooks.text}
      >
        {t('app.bookings-form.booking-details.booking-policy.sms-notification')}
      </TextComponent>
    </div>
  );
};

export default SmsNotification;
