export enum CartModalDataHooks {
  INPUT_DIALOG = 'cart-modal-input-dialog',
  CUSTOM_DIALOG = 'cart-modal-custom-dialog',
  CARD = 'cart-modal-card',
  CARD_TITLE = 'cart-modal-cart-title',
  CARD_IMAGE = 'cart-modal-cart-image',
  PRIMARY_BUTTON = 'cart-modal-primary-button',
  SECONDARY_BUTTON = 'cart-modal-secondary-button',
  MOBILE_MODAL = 'cart-modal-mobile',
  CLOSE_BUTTON = 'cart-modal-close-button',
}
