export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  BookOnBehalf: 'specs.bookings.BookOnBehalf',
  CancellationFeesUoU: 'specs.bookings.CancellationFeesUoU',
  DetachNumberOfParticipantsFieldFromForm:
    'specs.bookings.DetachNumberOfParticipantsFieldFromForm',
  FormUseAutomationsForSMS: 'specs.bookings.FormUseAutomationsForSMS',
  CheckIsMemberAreaInstalledUsingPublicAPI:
    'specs.bookings.CheckIsMemberAreaInstalledUsingPublicAPI',
  CheckForExistingBooking: 'specs.bookings.CheckForExistingBooking',
};
