import type { Experiments } from '@wix/yoshi-flow-editor';
import { ISettingsContextValue } from '@wix/tpa-settings';

type GetContentParams = {
  settings: Partial<ISettingsContextValue>;
  settingsParam: any;
  experiments?: Experiments;
};

export const getContent = ({ settings, settingsParam }: GetContentParams) => {
  return (
    settings.get?.(settingsParam)?.trim() ||
    settings.getDefaultValue?.(settingsParam)
  );
};
