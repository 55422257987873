import React from 'react';
import { SectionNotification, ButtonSize } from 'wix-ui-tpa/cssVars';
import AlertIcon from 'wix-ui-icons-common/on-stage/Alert';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import { classes } from './SectionMessage.st.css';
import { SectionMessageHook } from './dataHooks';

export enum SectionMessageType {
  Error = 'error',
  Alert = 'alert',
  Default = 'default',
}

export interface SectionMessageProps {
  text?: string;
  type: SectionMessageType;
  withoutIcon?: boolean;
  buttonText?: string;
  children?: React.ReactNode;
  buttonOnClick?: () => void;
}

export const SectionMessage: React.FC<SectionMessageProps> = ({
  text,
  type,
  withoutIcon = false,
  buttonText = '',
  children,
  buttonOnClick,
}) => {
  const getIcon = () =>
    type === SectionMessageType.Error ? <ErrorIcon /> : <AlertIcon />;

  const shouldShowNotification = text || children;

  return shouldShowNotification ? (
    <div
      className={classes.root}
      data-hook={SectionMessageHook.Section_Message}
    >
      <SectionNotification
        type={type}
        data-hook={SectionMessageHook.Section_Notification}
      >
        {!withoutIcon ? <SectionNotification.Icon icon={getIcon()} /> : null}
        <SectionNotification.Text>{text || children}</SectionNotification.Text>
        {buttonText ? (
          <SectionNotification.Button
            onClick={buttonOnClick}
            size={ButtonSize.tiny}
          >
            {buttonText}
          </SectionNotification.Button>
        ) : null}
      </SectionNotification>
    </div>
  ) : null;
};
