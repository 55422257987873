import { PaymentType } from '../../../types/types';
import { SlotService, SlotServices } from '../../state/types';
import { mapToArray } from '../..';

export const getSelectedServicesForBuyPlan = (
  slotServices: SlotServices,
): SlotService[] => {
  return mapToArray<SlotService>(slotServices).filter(
    (slotService) =>
      slotService?.selectedPaymentOption?.type === PaymentType.BUY_PLAN,
  );
};

export const checkDifferentBuyPlans = (slotServices: SlotServices): boolean => {
  const selectedServicesForBuyPlan =
    getSelectedServicesForBuyPlan(slotServices);

  const plans = new Set(
    selectedServicesForBuyPlan.map(
      (slotService) => slotService.selectedPaymentOption.id,
    ),
  );

  return plans.size > 1;
};
