import React from 'react';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { DurationDataHooks } from './dataHooks';
import { TagName } from '../../../../utils/tagName.const';
import {
  getCourseTotalNumberOfSessionsDurationText,
  getFormattedDuration,
} from '../../../../utils/duration/duration';
import Text, { TextType } from '../../Text/Text';
import { FormNestedSlot } from '../../../../types/formSelectedSlots';
import { classes } from './Duration.st.css';

export interface DurationProps {
  totalNumberOfSessions: number;
  slot: FormNestedSlot;
}

const Duration: React.FC<DurationProps> = ({ totalNumberOfSessions, slot }) => {
  const { t } = useTranslation();
  const { startDate, endDate } = slot;

  const duration = getDuration({
    totalNumberOfSessions,
    startDate: startDate!,
    endDate: endDate!,
    t,
  });

  return (
    <>
      <Text
        type={TextType.Secondary}
        data-hook={DurationDataHooks.DURATION}
        tagName={TagName.Paragraph}
        aria-hidden="true"
      >
        {duration.durationText}
      </Text>
      <div
        data-hook={DurationDataHooks.ARIA_DURATION}
        className={classes.srOnly}
      >
        {duration.durationAriaText}
      </div>
    </>
  );
};

const getDuration = ({
  totalNumberOfSessions,
  startDate,
  endDate,
  t,
}: {
  totalNumberOfSessions: number;
  startDate: string;
  endDate: string;
  t: TFunction;
}) => {
  return totalNumberOfSessions > 1
    ? getCourseTotalNumberOfSessionsDurationText(totalNumberOfSessions, t)
    : getFormattedDuration({
        startDate,
        endDate,
        t,
      });
};
export default Duration;
