import React, { FC } from 'react';
import Text, { TextType } from '../../../Text/Text';
import { TagName } from '../../../../../utils/tagName.const';
import { classes } from './PriceItem.st.css';
import { PriceItemDataHooks } from './dataHooks';

export type PriceItemProps = {
  type: TextType;
  labels: string[];
  price: string;
  ariaPrice: string;
  dataHook?: string;
};

export const PriceItem: FC<PriceItemProps> = ({
  type,
  labels,
  price,
  ariaPrice,
  dataHook,
}) => {
  return (
    <div
      className={classes.root}
      data-hook={dataHook || PriceItemDataHooks.Item}
    >
      <div>
        {labels.map((label, index) => (
          <Text
            key={index}
            type={type}
            tagName={TagName.Paragraph}
            data-hook={PriceItemDataHooks.Label}
          >
            {label}
          </Text>
        ))}
      </div>
      <Text
        className={classes.price}
        type={type}
        data-hook={PriceItemDataHooks.Price}
        tagName={TagName.Span}
        aria-label={ariaPrice}
      >
        {price}
      </Text>
    </div>
  );
};
