import {
  DoubleBookingActions,
  DoubleBookingComponents,
  DoubleBookingModalTypes,
} from '../../../../types/biLoggerTypes';
import { CreateActionParams } from '../actions';
import { bookingsUoUBookTwiceModalLoadClicksSrc16Evid1151 } from '@wix/bi-logger-bookings-data/v2';

export enum ModalCloseOrigin {
  CloseButton = 'CloseButton',
  BookAnyway = 'BookAnyway',
}

export type CloseAlreadyBookedModal = (
  origin: ModalCloseOrigin,
) => Promise<void>;

export function createCloseAlreadyBookedModalAction({
  context,
  getControllerState,
}: CreateActionParams): CloseAlreadyBookedModal {
  return async (origin) => {
    const { biLogger } = context;
    const [, setState] = getControllerState();

    let component = DoubleBookingComponents.Close;

    if (origin === ModalCloseOrigin.BookAnyway) {
      component = DoubleBookingComponents.BookAnyway;
    }

    biLogger?.report(
      bookingsUoUBookTwiceModalLoadClicksSrc16Evid1151({
        component,
        action: DoubleBookingActions.Click,
        modalType: DoubleBookingModalTypes.Member,
      }),
    );

    setState({ existingBookingDetails: undefined });
  };
}
