import React, { Suspense } from 'react';
import { Card } from 'wix-ui-tpa/cssVars';
import { CartModalDataHooks } from '../dataHooks';
import Text from '../../../Text/Text';
import DateAndTime from '../../DateAndTime/DateAndTime';
import { classes, st } from './ModalContent.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { FormSelectedSlot } from '../../../../../types/formSelectedSlots';
import { ServiceData } from '../../../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../../../utils';
import EmptyStatePage from '../../EmptyStatePage/EmptyStatePage';
import UpsellPlugin from '../../upsellPlugin';
import { useFormComponentContext } from '../../../Hooks/useFormComponentContext';

const ServiceImage = React.lazy(
  () => import('../../ServiceImage/ServiceImage'),
);

export interface ModalContentProps {
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  serviceData,
  formSelectedSlot,
}) => {
  const { isRTL } = useEnvironment();
  const { t } = useTranslation();
  const { isUpsellPluginInstalled } = useFormComponentContext();

  const firstNestedSlot = formSelectedSlot.nestedSlots[0];

  const serviceIdentifier = getServiceSlotIdentifier(firstNestedSlot);
  const service = serviceData.slotServices[serviceIdentifier]?.service;
  const serviceImage =
    serviceData.slotServices[serviceIdentifier]?.service.images?.[0];

  return (
    <Suspense fallback={<EmptyStatePage isProcessing />}>
      <Card
        data-hook={CartModalDataHooks.CARD}
        className={st(classes.card, { rtl: isRTL })}
      >
        {serviceImage && serviceData.isSingleService && (
          <Card.Container className={classes.left}>
            <ServiceImage
              {...serviceImage}
              dataHook={CartModalDataHooks.CARD_IMAGE}
            />
          </Card.Container>
        )}
        <Card.Container className={classes.right}>
          <div>
            <Text isBlackOnly data-hook={CartModalDataHooks.CARD_TITLE}>
              {serviceData.isSingleService
                ? service.name
                : t('app.bookings-form.cart-modal.services-text', {
                    numOfServices: formSelectedSlot.nestedSlots.length,
                  })}
            </Text>
            <DateAndTime
              totalNumberOfSessions={1}
              slot={firstNestedSlot}
              service={service}
              isBlackOnly
            />
          </div>
        </Card.Container>
      </Card>

      {isUpsellPluginInstalled && <UpsellPlugin />}
    </Suspense>
  );
};
