import { useRef } from 'react';

export default function useTimeout() {
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const clear = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  const set = (...params: Parameters<typeof setTimeout>) => {
    clear();
    timeout.current = setTimeout(...params);
  };

  return {
    set,
    clear,
  };
}
