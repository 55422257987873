export type FormErrorsParams = { [key: string]: any };

export type FormError = {
  errorType: FormErrorsType;
  params?: FormErrorsParams;
};

export type FormErrorsType =
  | CouponsErrorType
  | EmptyStateErrorType
  | GenericErrorType
  | CreateBookingErrorType
  | BookErrorType
  | ServerErrorType
  | DynamicPriceErrorType
  | CartConflictErrorType;

export enum CouponsErrorType {
  COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  COUPON_SERVICE_UNAVAILABLE = 'ERROR_COUPON_SERVICE_UNAVAILABLE',
  COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
  NOT_VALID_FOR_SELECTED_SERVICE = 'ERROR_INVALID_PRODUCTS',
  GENERAL_SERVER_ERROR = 'ERROR_COUPON_GENERAL',
  COUPON_LIMIT_PER_CUSTOMER_EXCEEDED = 'ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED',
}

export enum DynamicPriceErrorType {
  NOT_ENOUGH_SESSIONS_FOR_DYNAMIC_PRICE = 'NOT_ENOUGH_SESSIONS_FOR_DYNAMIC_PRICE',
  MISSING_REQUIRED_FIELD_DATA = 'MISSING_REQUIRED_FIELD_DATA',
}

export enum PreviewErrorType {
  INVALID_LINE_ITEM = 'INVALID_LINE_ITEM',
  FAILED_RESOLVING_SERVICE = 'FAILED_RESOLVING_SERVICE',
  NUMBER_OF_PARTICIPANTS_NOT_FOUND = 'NUMBER_OF_PARTICIPANTS_NOT_FOUND',
}

export enum CreateBookingErrorType {
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  SCHEDULE_CAPACITY_EXCEEDED = 'SCHEDULE_CAPACITY_EXCEEDED',
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = 'EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
  VIOLATIONS_WITH_ERROR_SEVERITY = 'VIOLATIONS_WITH_ERROR_SEVERITY',
}

export enum EmptyStateErrorType {
  INVALID_SLOT_AVAILABILITY = 'invalid_slot_availability',
  INVALID_SERVICE_ID = 'invalid_service_id',
  INVALID_CATALOG_DATA = 'invalid_catalog_data',
  INVALID_PAGE_API_DATA = 'invalid_page_api_data',
  COURSE_WITHOUT_SESSIONS = 'COURSE_WITHOUT_SESSIONS',
  NO_COURSE_AVAILABILITY = 'NO_COURSE_AVAILABILITY',
  OPTIONS_AND_VARIANTS_FAILED = 'OPTIONS_AND_VARIANTS_FAILED',
  NO_LIST_SLOTS = 'NO_LIST_SLOTS',
  CANNOT_FETCH_ECOM_ROLLOUT_STATUS = 'CANNOT_FETCH_ECOM_ROLLOUT_STATUS',
}

export enum GenericErrorType {
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = 'EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS',
  GENERIC_PRICING_PLAN_ERROR = 'GENERIC_PRICING_PLAN_ERROR',
  GENERIC_MEMBER_DETAILS_ERROR = 'GENERIC_MEMBER_DETAILS_ERROR',
  GENERIC_BOOK_ERROR = 'GENERIC_BOOK_ERROR',
  OVERLAPPING_SLOTS_ON_CART_ERROR = 'GENERIC_OVERLAPPING_SLOTS_ON_CART_ERROR',
}

export enum CartConflictErrorType {
  OVERLAPPING_SLOTS_ON_CART_ERROR = 'OVERLAPPING_SLOTS_ON_CART_ERROR',
}

export enum BookErrorType {
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  CANT_BOOK_CANCELED_SESSION = 'CANT_BOOK_CANCELED_SESSION',
  SESSION_TIME_MISMATCH = 'SESSION_TIME_MISMATCH',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
}

export enum ServerErrorType {
  OPTIONS_AND_VARIANTS_FAILED = 'OPTIONS_AND_VARIANTS_FAILED',
  COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  COUPON_SERVICE_UNAVAILABLE = 'ERROR_COUPON_SERVICE_UNAVAILABLE',
  COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
  NOT_VALID_FOR_SELECTED_SERVICE = 'ERROR_INVALID_PRODUCTS',
  COUPON_LIMIT_PER_CUSTOMER_EXCEEDED = 'ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED',
  GENERAL_SERVER_ERROR = 'ERROR_SERVER_GENERAL',
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = 'EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS',
  GENERIC_PRICING_PLAN_ERROR = 'GENERIC_PRICING_PLAN_ERROR',
  GENERIC_MEMBER_DETAILS_ERROR = 'GENERIC_MEMBER_DETAILS_ERROR',
  GENERIC_BOOK_ERROR = 'GENERIC_BOOK_ERROR',
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  SCHEDULE_CAPACITY_EXCEEDED = 'SCHEDULE_CAPACITY_EXCEEDED',
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
  INVALID_SLOT_AVAILABILITY = 'INVALID_SLOT_AVAILABILITY',
  INVALID_SERVICE_ID = 'INVALID_SERVICE_ID',
  INVALID_CATALOG_DATA = 'INVALID_CATALOG_DATA',
  COURSE_WITHOUT_SESSIONS = 'COURSE_WITHOUT_SESSIONS',
  NO_COURSE_AVAILABILITY = 'NO_COURSE_AVAILABILITY',
  NO_LIST_SLOTS = 'NO_LIST_SLOTS',
  NO_PRICING_PLAN_INSTALLED_BUT_SERVICE_OFFERED_ONLY_AS_PP = 'NO_PRICING_PLAN_INSTALLED_BUT_SERVICE_OFFERED_ONLY_AS_PP',
  CANNOT_FETCH_ECOM_ROLLOUT_STATUS = 'CANNOT_FETCH_ECOM_ROLLOUT_STATUS',
  CANT_BOOK_CANCELED_SESSION = 'CANT_BOOK_CANCELED_SESSION',
  SESSION_TIME_MISMATCH = 'SESSION_TIME_MISMATCH',
  INVALID_LINE_ITEM = 'INVALID_LINE_ITEM',
  FAILED_RESOLVING_SERVICE = 'FAILED_RESOLVING_SERVICE',
  NUMBER_OF_PARTICIPANTS_NOT_FOUND = 'NUMBER_OF_PARTICIPANTS_NOT_FOUND',
  FAILED_TO_FETCH_DEFAULT_FORM_DATA = 'FAILED_TO_FETCH_DEFAULT_FORM_DATA',
  FAILED_TO_FETCH_DEFAULT_BOOKING_POLICY = 'FAILED_TO_FETCH_DEFAULT_BOOKING_POLICY',
}
