import * as React from 'react';
import { SlotsPlaceholder } from '@wix/widget-plugins-ooi';
import { classes } from './index.st.css';
import { PlatformIdentifiers } from '../../../../types/types';

const UpsellPlugin = () => {
  return (
    <div className={classes.root}>
      <SlotsPlaceholder
        slotId={PlatformIdentifiers.BOOKINGS_FORM_CART_MODAL_SLOT_ID}
      />
    </div>
  );
};

export default UpsellPlugin;
