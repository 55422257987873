import React from 'react';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { UserSelectionDataHooks } from './dataHooks';
import { classes } from './UserSelection.st.css';
import { useFormActions } from '../../Hooks/useFormActions';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';
import { Member } from '@wix/ambassador-members-ng-api/types';
import TextComponent, { TextType } from '../../Text/Text';
import PreviewTooltip from '../PreviewTooltip/PreviewTooltip';

export interface UserSelectionProps {
  memberDetails?: Member;
  children?: React.ReactNode;
}

export const UserSelection: React.FC<UserSelectionProps> = ({
  memberDetails,
}) => {
  const { promptLogin, onLogout } = useFormActions();
  const userFirstName = memberDetails?.contact?.firstName;
  const isLoggedIn = memberDetails !== undefined;

  const handleUserSelection = () => {
    if (isLoggedIn) {
      onLogout();
    }
    promptLogin();
  };

  return (
    <div
      data-hook={UserSelectionDataHooks.USER_SELECTION_COMPONENT}
      className={classes.root}
    >
      <TextComponent type={TextType.Secondary}>
        <Trans
          i18nKey={getUserSelectionTranslationKey(isLoggedIn, userFirstName)}
          values={{ name: userFirstName }}
        >
          <LoginButton handleUserSelection={handleUserSelection} />
        </Trans>
      </TextComponent>
    </div>
  );
};

const LoginButton: React.FC<{
  handleUserSelection: () => void;
  children?: React.ReactNode;
}> = ({ handleUserSelection, children }) => {
  const { t } = useTranslation();
  return (
    <PreviewTooltip content={t('app.preview.tooltips.user-selection-button')}>
      <TextButton
        data-hook={UserSelectionDataHooks.USER_SELECTION_BUTTON}
        className={classes.link}
        onClick={() => handleUserSelection()}
      >
        {children}
      </TextButton>
    </PreviewTooltip>
  );
};

function getUserSelectionTranslationKey(
  isLoggedIn: boolean,
  userFirstName: string | undefined,
): string {
  if (isLoggedIn) {
    if (userFirstName) {
      return 'app.switch-user';
    }
    return 'app.switch-user-constant';
  }
  return 'app.login';
}
