import React from 'react';
import Text, { TextType } from '../../../../Text/Text';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../../../types/types';
import { TagName } from '../../../../../../utils/tagName.const';
import { PaymentDetails } from '../../../PaymentDetails/PaymentDetails';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { SlotService } from '../../../../../../utils/state/types';
import { getPriceLineText } from '../../../../../../utils/payment';
import { SelectedVariants } from '@wix/bookings-uou-types';
import { useFormComponentContext } from '../../../../Hooks/useFormComponentContext';

export type PriceLineProps = {
  dataHook: string;
  selectedPaymentOption: PaymentOption;
  slotService: SlotService;
  selectedVariant?: SelectedVariants;
  totalNumberOfParticipants?: number;
};

const PriceLine: React.FC<PriceLineProps> = ({
  dataHook,
  selectedPaymentOption,
  slotService,
  selectedVariant,
  totalNumberOfParticipants,
}) => {
  const { dateRegionalSettingsLocale } = useFormComponentContext();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  if (selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession) {
    const priceText: string | null = getPriceLineText({
      t,
      dateRegionalSettingsLocale,
      slotService,
      selectedVariant,
      totalNumberOfParticipants,
      experiments,
    });
    return priceText ? (
      <Text
        type={TextType.Secondary}
        data-hook={dataHook}
        tagName={TagName.Paragraph}
      >
        {priceText}
      </Text>
    ) : null;
  } else {
    return <PaymentDetails paymentOption={selectedPaymentOption} />;
  }
};

export default PriceLine;
