import React, { FC } from 'react';
import { classes } from './CenterVertically.st.css';

export interface CenterVerticallyProps {
  children: React.ReactNode;
}

export const CenterVertically: FC<CenterVerticallyProps> = ({ children }) => {
  return <div className={classes.root}>{children}</div>;
};
