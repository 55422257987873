const DARK_COLOR_THRESHOLD = 150;

const getBrightness = (color: string): number => {
  const parsedColor = parseColor(color);
  if (parsedColor) {
    const { r, g, b, a } = parsedColor;
    if (a < 0.8) {
      return DARK_COLOR_THRESHOLD;
    }
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
  return 0;
};

interface ParsedColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

const parseColor = (
  color: string,
  forcedOpacity?: number,
): ParsedColor | null => {
  if (!color) {
    return null;
  }
  if (color.includes('rgba')) {
    return parseRgbaColor(color, forcedOpacity);
  }
  if (color.includes('#')) {
    return parseHexColor(color, forcedOpacity);
  }
  if (color.includes('rgb')) {
    return parseRgbColor(color, forcedOpacity);
  }
  return null;
};

const parseRgbColor = (
  color: string,
  forcedOpacity: number = 1,
): ParsedColor => {
  const [r, g, b] = color
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map(Number);
  return { r, g, b, a: forcedOpacity };
};

const parseHexColor = (
  hex: string,
  forcedOpacity: number = 1,
): ParsedColor | null => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) {
    const [r, g, b] = normal.slice(1).map((e) => parseInt(e, 16));
    return { r, g, b, a: forcedOpacity };
  }

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) {
    const [r, g, b] = shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));
    return { r, g, b, a: 1 };
  }
  return null;
};

const parseRgbaColor = (
  rgbaString: string,
  forceOpacity?: number,
): ParsedColor | null => {
  const match =
    /^rgba?\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)(\s*,\s*([0-9]+(\.[0-9]+)?))?\s*\)$/i.exec(
      rgbaString,
    );

  if (match) {
    let opacity = Number(forceOpacity || match[5]);

    if (Object.is(opacity, undefined)) {
      opacity = 1;
    }

    return {
      r: parseInt(match[1], 10),
      g: parseInt(match[2], 10),
      b: parseInt(match[3], 10),
      a: opacity,
    };
  }

  return null;
};

export const colorToRgba = (color: string, forcedOpacity?: number) => {
  const c = parseColor(color, forcedOpacity);
  if (!c) {
    return null;
  }
  return `rgba(${c.r},${c.g},${c.b},${c.a})`;
};

export const isDarkColor = (color?: string): boolean =>
  color ? getBrightness(color) < DARK_COLOR_THRESHOLD : false;
