// evid 16:636
export enum ReferralInfo {
  AddMoreSessions = 'add more sessions',
}

// evid 16:659
export enum FormClickNextAction {
  ChooseAPlan = 'choose a plan',
  AddToCart = 'add to cart',
}

// evid 16:1052
export enum FormClickAction {
  Payment = 'payment',
  Login = 'login',
  Back = 'back',
  ChoosePaymentOption = 'choose payment option',
}

export enum FormClickComponent {
  Payment = 'Payment',
}

export enum FormClickActionValue {
  PricingPlan = 'pricing plan',
  SingleSessions = 'single sessions',
  Online = 'online',
  Offline = 'offline',
}

// evid 16:1072
export enum CartModalInteractionActionName {
  Load = 'load',
  GoToCheckout = 'go to checkout',
  GoToViewCart = 'go to view cart',
  AddMoreSessions = 'add more sessions',
  Close = 'close',
  OverlayClose = 'overlay close',
}

// evid 16:1151
export enum DoubleBookingComponents {
  Modal = 'modal',
  GoBack = 'go back',
  BookAnyway = 'book anyway',
  Close = 'close',
}

export enum DoubleBookingActions {
  Load = 'load',
  Click = 'click',
}

export enum DoubleBookingModalTypes {
  Member = 'book twice warning member',
  Visitor = 'book twice warning visitor',
}
