import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import React from 'react';
import { Button, ButtonPriority } from 'wix-ui-tpa/cssVars';
import { classes, st } from './ModalFooter.st.css';
import { isDarkColor } from '../../../../../utils/colors/colors';
import stylesParams from '../../../stylesParams';

export type ButtonProp = {
  dataHook?: string;
  text: string;
  onClick: () => {};
  loading: boolean;
};

export interface ModalFooterProps {
  primaryButton?: ButtonProp;
  secondaryButton?: ButtonProp;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  primaryButton,
  secondaryButton,
}) => {
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const forceBlack = !isDarkColor(
    styles.get(stylesParams.mainButtonBackgroundColorAndOpacity).value,
  );
  return (
    <div className={st(classes.root, { mobile: isMobile, forceBlack })}>
      {primaryButton && (
        <Button
          className={classes.primaryButton}
          data-hook={primaryButton.dataHook}
          upgrade
          priority={ButtonPriority.basic}
          onClick={primaryButton.onClick}
          disabled={primaryButton.loading}
        >
          {primaryButton.text}
        </Button>
      )}
      {secondaryButton && (
        <Button
          className={classes.secondaryButton}
          data-hook={secondaryButton.dataHook}
          upgrade
          priority={ButtonPriority.secondary}
          onClick={secondaryButton.onClick}
          disabled={secondaryButton.loading}
        >
          {secondaryButton.text}
        </Button>
      )}
    </div>
  );
};
