import {
  isServiceOfferedAsPricingPlan,
  ServicePayment,
} from '../../../mappers/serviceMapper/utils/payment.mapper';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { PaymentDtoMapper } from '@wix/bookings-uou-mappers';
import {
  PaymentOption,
  PaymentType,
  ReservedPaymentOptionIds,
  TFunction,
} from '../../../../types/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { getContent } from '../../../content/content';
import settingsParams from '../../../../components/BookingsForm/settingsParams';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import {
  findBenefitWithPlanInfo,
  isDisabledMembership,
  isOfferedAsOneTime,
} from '../utils';

export const getPaymentOptions = ({
  benefitsWithPlanInfo,
  memberships,
  servicePayment,
  t,
  settings,
  dateAndTimeFormatter,
  isPricingPlanInstalled,
  numberOfParticipants = 1,
  dateRegionalSettingsLocale,
  isDynamicPreferenceType,
}: {
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  memberships?: ListEligibleMembershipsResponse;
  servicePayment: ServicePayment;
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  dateAndTimeFormatter: DateTimeFormatter;
  isPricingPlanInstalled: boolean;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  isDynamicPreferenceType?: boolean;
}): PaymentOption[] => {
  if (!servicePayment) {
    return [];
  }

  const pricingPlans: PaymentOption[] =
    memberships?.eligibleMemberships!.map((membership) => {
      const disabled = isDisabledMembership({
        membership,
        numberOfParticipants,
      });
      return {
        id: membership.id!,
        label: membership.name?.original!,
        benefitInfo: findBenefitWithPlanInfo(
          membership.additionalData?.benefitId,
          benefitsWithPlanInfo,
        ),
        disabled,
        ...(membership?.credits?.remaining && membership?.credits?.total
          ? {
              suffix:
                membership.credits.remaining + '/' + membership.credits.total,
            }
          : {}),
        ...(membership.expirationDate
          ? {
              validUntil: t('app.payment.valid-until.text', {
                validUntil: dateAndTimeFormatter.formatDate(
                  membership.expirationDate.toISOString(),
                ),
              }),
            }
          : {}),
        ...(membership?.credits?.remaining && {
          creditRemain: membership.credits.remaining,
        }),
        type: PaymentType.USE_PLAN,
      };
    }) || [];

  const showBuyAPricingPlan =
    isServiceOfferedAsPricingPlan(servicePayment, isPricingPlanInstalled) &&
    pricingPlans.length === 0;
  const showPaySingleSession = isOfferedAsOneTime(servicePayment);

  return [
    ...pricingPlans,
    ...(showBuyAPricingPlan
      ? [
          {
            id: ReservedPaymentOptionIds.BuyAPricingPlan,
            label: getContent({
              settings,
              settingsParam: settingsParams.pricingPlanText,
            }),
            disabled: false,
            type: PaymentType.BUY_PLAN,
          },
        ]
      : []),
    ...(showPaySingleSession
      ? [
          {
            id: ReservedPaymentOptionIds.SingleSession,
            ...getPriceText({
              payment: servicePayment,
              dateRegionalSettingsLocale,
              settings,
              isDynamicPreferenceType,
              t,
            }),
            disabled: false,
            type: PaymentType.SINGLE_SESSION,
          },
        ]
      : []),
  ];
};

const getPriceText = ({
  payment,
  dateRegionalSettingsLocale,
  settings,
  isDynamicPreferenceType,
  t,
}: {
  payment: ServicePayment;
  dateRegionalSettingsLocale: string;
  settings: ControllerFlowAPI['settings'];
  isDynamicPreferenceType?: boolean;
  t?: TFunction;
}) => {
  const price = new PaymentDtoMapper(dateRegionalSettingsLocale, (p: string) =>
    payment.paymentDetails.isVariedPricing && t
      ? t('app.payment.from-price.text', { price: p })
      : p,
  ).priceText(payment.paymentDetails);
  if (payment.paymentDetails.priceText) {
    return {
      label: price,
    };
  }
  return {
    label: getContent({
      settings,
      settingsParam: settingsParams.singleSessionText,
    }),
    ...(!isDynamicPreferenceType ? { suffix: price } : {}),
  };
};
