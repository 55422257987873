import {
  useEnvironment,
  useTranslation,
  ControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import React, { useState, useEffect } from 'react';
import { CustomDialog, IconButton } from 'wix-ui-tpa/cssVars';
import { CartFlow, CartModalStatus } from '../../../../types/types';
import { useFormActions } from '../../Hooks/useFormActions';
import { CartModalDataHooks } from './dataHooks';
import { ModalContent } from './ModalContent/ModalContent';
import { ButtonProp, ModalFooter } from './ModalFooter/ModalFooter';
import { classes, st } from './CartModal.st.css';
import { MobileModal } from './MobileModal/MobileModal';
import { FormSelectedSlot } from '../../../../types/formSelectedSlots';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { mapToArray } from '../../../../utils';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { TagName } from '../../../../utils/tagName.const';
import Text, { TextType } from '../../Text/Text';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';

export interface CartModalProps {
  cartModalStatus: CartModalStatus;
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  bookingIds?: string[];
  settings: ControllerFlowAPI['settings'];
}

const CartModal: React.FC<CartModalProps> = ({
  cartModalStatus,
  serviceData,
  formSelectedSlot,
  bookingIds,
  settings,
}) => {
  const { t } = useTranslation();
  const { onCartCTAClick, upsellPluginSendBookingIds } = useFormActions();
  const { isMobile, isRTL } = useEnvironment();
  const isOpen = cartModalStatus === CartModalStatus.OPEN;
  const slotService = mapToArray<SlotService>(serviceData.slotServices)[0];
  const { isUpsellPluginInstalled } = useFormComponentContext();

  useEffect(() => {
    if (isOpen) {
      upsellPluginSendBookingIds(bookingIds || []);
    }
  }, [isOpen]);

  const onClose = () => onCartCTAClick(slotService, CartFlow.CLOSE_MODAL);

  const onOverlayClose = () =>
    onCartCTAClick(slotService, CartFlow.CLOSE_MODAL_OVERLAY);

  const title = t('app.booking-details.cart-modal.title');

  const [primaryButtonLoading, setPrimaryButtonLoading] =
    useState<boolean>(false);
  const [secondaryaryButtonLoading, setSecondaryaryButtonLoading] =
    useState<boolean>(false);

  let primaryButton: ButtonProp | undefined;
  if (settings.get(settingsParams.addMoreSessionsButtonVisible)) {
    primaryButton = {
      dataHook: CartModalDataHooks.PRIMARY_BUTTON,
      text: getContent({
        settings,
        settingsParam: settingsParams.bookAnotherSessionButtonText,
      }),
      onClick: async () => {
        setPrimaryButtonLoading(true);
        await onCartCTAClick(slotService, CartFlow.ADD_MORE_SESSIONS);
      },
      loading: primaryButtonLoading,
    };
  }

  const secondaryButtonText = getContent({
    settings,
    settingsParam: settingsParams.viewCartButtonText,
  });

  const secondaryButton: ButtonProp = {
    dataHook: CartModalDataHooks.SECONDARY_BUTTON,
    text: t(secondaryButtonText),
    onClick: async () => {
      setSecondaryaryButtonLoading(true);
      await onCartCTAClick(slotService, CartFlow.CHECKOUT);
    },
    loading: secondaryaryButtonLoading,
  };

  if (isMobile) {
    return (
      <MobileModal
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        serviceData={serviceData}
        formSelectedSlot={formSelectedSlot}
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClose={onOverlayClose}
        title={title}
        dataHook={CartModalDataHooks.MOBILE_MODAL}
      />
    );
  }

  return (
    <CustomDialog
      className={st(classes.root, { rtl: isRTL })}
      data-hook={CartModalDataHooks.CUSTOM_DIALOG}
      isOpen={isOpen}
      onClose={onOverlayClose}
    >
      <div className={classes.dialogBase}>
        <div className={classes.closeButton}>
          <IconButton
            data-hook={CartModalDataHooks.CLOSE_BUTTON}
            icon={<Close />}
            onClick={onClose}
            aria-label={t(
              'app.bookings-form.add-to-cart-modal.close-button.aria-label',
            )}
          />
        </div>
        <div className={[classes.margins, classes.marginBottom].join(' ')}>
          <Text tagName={TagName.Span} type={TextType.Title} isBlackOnly>
            {title}
          </Text>
        </div>
        <div
          className={[
            classes.contentWrapper,
            ...(isUpsellPluginInstalled ? [classes.marginBottom] : []),
          ].join(' ')}
        >
          <ModalContent
            serviceData={serviceData}
            formSelectedSlot={formSelectedSlot}
          />
        </div>
        <div className={[classes.margins, classes.footerWrapper].join(' ')}>
          <ModalFooter
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default CartModal;
