import {
  Service,
  Payment,
  RateType,
  Money,
} from '@wix/ambassador-bookings-services-v2-service/types';

export enum OfferedAsType {
  ONE_TIME = 'ONE_TIME',
  PRICING_PLAN = 'PRICING_PLAN',
}

export enum PaymentType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  BOTH = 'BOTH',
}

export interface MoneyDto {
  currency: string;
  price: number;
  formattedValue: string;
}

export interface ServicePaymentDto {
  currency: string;
  price: number;
  priceText: string;
  minCharge: number;
  isFree: boolean;
  isVariedPricing?: boolean;
  paymentType: PaymentType;
  displayTextForPlan?: string;
  minPrice?: MoneyDto;
  maxPrice?: MoneyDto;
}

export interface PricingPlanInfo {
  displayText: string;
  pricingPlans: string[];
}

export interface ServicePayment {
  offeredAs: OfferedAsType[];
  paymentDetails: ServicePaymentDto;
  pricingPlanInfo?: PricingPlanInfo;
}

export interface PaymentOptions {
  custom?: Boolean;
  wixPaidPlan?: Boolean;
  wixPayInPerson?: Boolean;
  wixPayOnline?: Boolean;
}

export const mapServicePayment = (service: Service): ServicePayment => {
  return {
    offeredAs: mapServiceOfferedAsDto(service),
    pricingPlanInfo: mapServicePricingPlansDto(service),
    paymentDetails: mapServicePaymentDto(service),
  };
};
const isPayable = (payment: Payment) => {
  return payment.options?.inPerson || payment.options?.online;
};

export const determinePaymentOptionsBy = (
  payment: Payment,
): OfferedAsType[] => {
  const pricingPlanIds = payment.pricingPlanIds;
  return [
    ...(pricingPlanIds?.length && payment.options?.pricingPlan
      ? [OfferedAsType.PRICING_PLAN]
      : []),
    ...(isPayable(payment) ? [OfferedAsType.ONE_TIME] : []),
  ];
};

export const getPriceText = (payment: Payment) =>
  payment?.rateType === RateType.CUSTOM && payment.custom?.description
    ? payment.custom.description
    : '';

export const mapServicePricingPlansDto = (
  service: Service,
): PricingPlanInfo => {
  const { payment } = service;
  const pricingPlansIds = payment?.pricingPlanIds || [];

  const displayText = getPriceText(payment!);

  return {
    displayText,
    pricingPlans: pricingPlansIds,
  };
};

export function mapServiceOfferedAsDto(service: Service) {
  const payment = service.payment;
  return determinePaymentOptionsBy(payment!);
}

export const isFreeService = (payment: Payment, price: number) => {
  return !!payment?.custom || (!price && !payment?.options?.pricingPlan);
};

export function mapPaymentOptionsToPaymentType(payment: Payment): PaymentType {
  if (payment.options?.online && !payment.options?.inPerson) {
    return PaymentType.ONLINE;
  }
  if (!payment?.options?.online && payment?.options?.inPerson) {
    return PaymentType.OFFLINE;
  }
  return PaymentType.BOTH;
}

export function mapPaymentFromRate(payment: Payment) {
  const paymentInfo: {
    isFree: boolean;
    priceText: string;
    paymentType: PaymentType;
    currency: string;
    price: number;
    minCharge: number;
    isVariedPricing: boolean;
  } = {
    isFree: false,
    priceText: getPriceText(payment),
    paymentType: PaymentType.OFFLINE,
    currency: '',
    price: 0,
    minCharge: 0,
    isVariedPricing: false,
  };

  if (payment?.rateType === RateType.FIXED) {
    paymentInfo.currency = payment?.fixed?.price?.currency || '';
    paymentInfo.price = Number(payment?.fixed?.price?.value);
    paymentInfo.minCharge = Number(payment?.fixed?.deposit?.value);
  }

  if (payment?.rateType === RateType.VARIED) {
    paymentInfo.isVariedPricing = true;
    paymentInfo.currency = payment?.varied?.defaultPrice?.currency || '';
    paymentInfo.price = Number(payment.varied?.defaultPrice?.value);
    paymentInfo.minCharge = Number(payment.varied?.deposit?.value);
  }

  paymentInfo.isFree = isFreeService(payment, paymentInfo.price);
  paymentInfo.price = paymentInfo.isFree ? 0 : paymentInfo.price;

  paymentInfo.paymentType =
    payment && !paymentInfo.isFree
      ? mapPaymentOptionsToPaymentType(payment)
      : PaymentType.OFFLINE;

  return paymentInfo;
}

export function oneTimePricing(payment: Payment): {
  isFree: boolean;
  isVariedPricing?: boolean;
  priceText: string;
  paymentType: PaymentType;
  currency: string;
  price: number;
  deposit: number;
} {
  const paymentFromRate = mapPaymentFromRate(payment);
  return {
    isFree: paymentFromRate.isFree,
    priceText: paymentFromRate.priceText,
    paymentType: paymentFromRate.paymentType,
    currency: paymentFromRate.currency,
    price: paymentFromRate.price,
    deposit: paymentFromRate.minCharge,
    isVariedPricing: paymentFromRate.isVariedPricing,
  };
}

export const mapServiceMoneyDto = (money?: Money): MoneyDto => {
  if (!money) {
    throw new Error('Price is missing');
  }
  return {
    currency: money.currency || '',
    price: Number(money.value),
    formattedValue: money.formattedValue || '',
  };
};

export const getServiceMinAndMaxPrice = (service: Service) => {
  if (!service?.payment) {
    return {};
  }

  if (service.payment.varied) {
    return {
      maxPrice: mapServiceMoneyDto(service.payment.varied.maxPrice),
      minPrice: mapServiceMoneyDto(service.payment.varied.minPrice),
    };
  }
  if (service.payment.fixed) {
    return {
      maxPrice: mapServiceMoneyDto(service.payment.fixed.price),
      minPrice: mapServiceMoneyDto(service.payment.fixed.price),
    };
  }

  return {};
};

export const mapServicePaymentDto = (service: Service): ServicePaymentDto => {
  const {
    isFree,
    priceText,
    paymentType,
    currency,
    price,
    deposit,
    isVariedPricing,
  } = oneTimePricing(service.payment!);
  const { minPrice, maxPrice } = getServiceMinAndMaxPrice(service);
  return {
    currency,
    price,
    isFree,
    priceText,
    minCharge: deposit,
    paymentType,
    isVariedPricing,
    minPrice,
    maxPrice,
  };
};

export const isServiceOfferedAsPricingPlan = (
  paymentDto: ServicePayment,
  isPricingPlanInstalled: boolean,
) =>
  getOfferedAs(paymentDto, isPricingPlanInstalled).indexOf(
    OfferedAsType.PRICING_PLAN,
  ) >= 0;

export const getOfferedAs = (
  paymentDto: ServicePayment,
  isPricingPlanInstalled: boolean,
) => {
  if (
    paymentDto.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0 &&
    paymentDto.offeredAs.indexOf(OfferedAsType.PRICING_PLAN) >= 0
  ) {
    if (
      paymentDto.pricingPlanInfo?.pricingPlans.length === 0 ||
      !isPricingPlanInstalled
    ) {
      return [OfferedAsType.ONE_TIME];
    }
  }
  return paymentDto.offeredAs;
};
