import React from 'react';
import { classes } from './CartConflictMessage.st.css';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CartConflictMessageDataHooks } from './dataHooks';
import Text, { TextType } from '../../Text/Text';
import { CenterVertically } from '../../CenterVertically/CenterVertically';

interface CartConflictMessageProps {}

export const CartConflictMessage: React.FC<CartConflictMessageProps> = () => {
  const { t } = useTranslation();

  return (
    <div
      className={classes.root}
      data-hook={CartConflictMessageDataHooks.NOTIFICATION_MESSAGE}
    >
      <SectionMessage
        type={SectionMessageType.Error}
        withoutIcon
        buttonText={t('app.bookings-form.cart-conflict.back')}
        buttonOnClick={() => window.history.back()}
      >
        <CenterVertically>
          <Text isBlackOnly type={TextType.Primary}>
            {t('app.bookings-form.toast.cart-conflict.error')}
          </Text>
        </CenterVertically>
      </SectionMessage>
    </div>
  );
};
