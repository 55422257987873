import React from 'react';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { TagName } from '../../../../utils/tagName.const';
import { DateAndTimeDataHooks } from './dataHooks';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import Text, { TextType } from '../../Text/Text';
import { useEditorContext } from '../../Hooks/useEditorContext';
import { classes } from './DateAndTime.st.css';
import { FormService } from '../../../../utils/mappers/serviceMapper/service.mapper';
import { FormNestedSlot } from '../../../../types/formSelectedSlots';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';

export interface DateAndTimeProps {
  totalNumberOfSessions: number;
  slot: FormNestedSlot;
  service: FormService;
  isBlackOnly?: boolean;
}
const DateAndTime: React.FC<DateAndTimeProps> = ({
  service,
  totalNumberOfSessions,
  slot,
  isBlackOnly,
}) => {
  const { t } = useTranslation();
  const editorContext = useEditorContext();
  const { startDate, timezone, endDate } = slot;
  const isCourse = service.type === ServiceType.COURSE;
  const { dateRegionalSettingsLocale } = useFormComponentContext();

  const shouldSendTimezone =
    isCourse || startDate.at(startDate.length - 1) === 'Z';

  const endDateAndTime = shouldShowEndDate(slot, service)
    ? getDateAndTime({
        startDate: endDate,
        dateRegionalSettingsLocale,
        totalNumberOfSessions,
        t,
        timezone: shouldSendTimezone ? timezone : undefined,
      })
    : undefined;

  const dateAndTime = editorContext.isDummy
    ? t('app.dummy-data.date-and-time')
    : getDateAndTime({
        startDate: startDate!,
        dateRegionalSettingsLocale,
        totalNumberOfSessions,
        t,
        timezone: shouldSendTimezone ? timezone : undefined,
      });

  return (
    <div className={classes.root}>
      <Text
        type={TextType.Primary}
        data-hook={DateAndTimeDataHooks.SLOT_DATE_AND_TIME}
        tagName={TagName.Paragraph}
        isBlackOnly={isBlackOnly}
      >
        {dateAndTime}
      </Text>
      {endDateAndTime && (
        <Text
          type={TextType.Primary}
          data-hook={DateAndTimeDataHooks.SLOT_END_DATE_AND_TIME}
          tagName={TagName.Paragraph}
          isBlackOnly={isBlackOnly}
        >
          {endDateAndTime}
        </Text>
      )}
    </div>
  );
};

const getDateAndTime = ({
  startDate,
  dateRegionalSettingsLocale,
  totalNumberOfSessions,
  t,
  timezone,
}: {
  startDate: string;
  dateRegionalSettingsLocale: string;
  totalNumberOfSessions: number;
  t: TFunction;
  timezone?: string;
}) => {
  const dateAndTimeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale!,
    timezone,
  );
  const dateAndTimeFormat = dateAndTimeFormatter.formatDateAndTime(startDate);
  const isServiceHasMultipleSessions = totalNumberOfSessions > 1;

  return isServiceHasMultipleSessions
    ? t('app.booking-details.course.date-and-time.starts.text', {
        date: dateAndTimeFormat,
      })
    : dateAndTimeFormat;
};

const shouldShowEndDate = (slot: FormNestedSlot, service: FormService) => {
  if (service.type === ServiceType.COURSE) {
    return false;
  }

  const duration =
    new Date(slot.endDate).getTime() - new Date(slot.startDate).getTime();
  return duration / (24 * 60 * 60 * 1000) >= 7;
};

export default DateAndTime;
