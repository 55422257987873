import React, { FC } from 'react';
import { ExtendedBooking } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Modal } from '../../Modal';
import { AlreadyBookedModalDatahooks } from './datahooks';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { mapToArray } from '../../../../utils';
import { formatBookingTime } from '../../../../utils/time';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';
import { useFormActions } from '../../Hooks/useFormActions';
import { ModalCloseOrigin } from '../../Actions/closeAlreadyBookedModal/closeAlreadyBookedModal';
import { bookingsUoUBookTwiceModalLoadClicksSrc16Evid1151 } from '@wix/bi-logger-bookings-data/v2';
import {
  DoubleBookingActions,
  DoubleBookingComponents,
  DoubleBookingModalTypes,
} from '../../../../types/biLoggerTypes';

export interface AlreadyBookedModalProps {
  existingBookingDetails?: ExtendedBooking;
  memberDetails?: Member;
  serviceData: ServiceData;
}

const AlreadyBookedModal: FC<AlreadyBookedModalProps> = ({
  existingBookingDetails,
  memberDetails,
  serviceData,
}) => {
  const { closeAlreadyBookedModal, logBi } = useFormActions();

  const handleOnClose = (origin: ModalCloseOrigin) => {
    closeAlreadyBookedModal(origin);
  };
  const { t } = useTranslation();

  const { dateRegionalSettingsLocale } = useFormComponentContext();

  if (!memberDetails || !existingBookingDetails) {
    return null;
  }

  if (existingBookingDetails && memberDetails) {
    const slotService = mapToArray<SlotService>(serviceData.slotServices)[0];

    const startTime = formatBookingTime(
      slotService,
      existingBookingDetails,
      dateRegionalSettingsLocale,
    );

    const serviceName = slotService.service.name;
    return (
      <Modal
        modalTitle={t('app.bookings-form.double-booking-modal.title')}
        modalSubtitle={t('app.bookings-form.double-booking-modal.message', {
          startTime,
          serviceName,
        })}
        primaryButton={{
          label: t('app.bookings-form.double-booking-modal.confirm'),
          onClick: () => {
            logBi([
              bookingsUoUBookTwiceModalLoadClicksSrc16Evid1151({
                action: DoubleBookingActions.Click,
                component: DoubleBookingComponents.GoBack,
                modalType: DoubleBookingModalTypes.Member,
              }),
            ]);
            window.history.back();
          },
        }}
        secondaryButton={{
          label: t('app.bookings-form.double-booking-modal.cancel'),
          onClick: () => handleOnClose(ModalCloseOrigin.BookAnyway),
        }}
        onClose={() => handleOnClose(ModalCloseOrigin.CloseButton)}
        dataHook={AlreadyBookedModalDatahooks.Modal}
      />
    );
  }

  return null;
};

export default AlreadyBookedModal;
