import React from 'react';
import Text, { TextType } from '../Text/Text';
import { classes, st } from './Modal.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Dialog, Spinner, Button } from 'wix-ui-tpa/cssVars';
import { ModalDataHooks } from './datahooks';

export interface ModalProps {
  modalTitle?: string;
  isLoading?: boolean;
  modalSubtitle?: any;
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
  onClose?: () => void;
  dataHook: string;
}

export interface ModalButton {
  label: string;
  onClick?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  modalTitle,
  modalSubtitle,
  primaryButton,
  secondaryButton,
  isLoading,
  onClose,
  dataHook,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const renderButton = ({
    button,
    className,
    buttonDataHook,
  }: {
    button: ModalButton;
    className: string;
    buttonDataHook: string;
  }) => {
    return (
      <Button
        className={st(classes.button, className)}
        upgrade
        fullWidth={isMobile}
        onClick={button.onClick}
        data-hook={buttonDataHook}
        wrapContent
      >
        {button.label}
      </Button>
    );
  };

  const onCloseModal = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Dialog
      isOpen
      notFullscreenOnMobile
      onClose={onCloseModal}
      contentClassName={classes.content}
      data-hook={dataHook}
      forceBWTheme
      closeButtonAriaLabel={t('app.bookings-form.close-modal.aria-label')}
    >
      <div className={classes.modalContent} data-hook={ModalDataHooks.Main}>
        {isLoading ? (
          <div className={st(classes.spinnerWrapper, { isMobile })}>
            <Spinner isCentered data-hook={ModalDataHooks.Spinner} />
          </div>
        ) : (
          <>
            {modalTitle && (
              <Text
                data-hook={ModalDataHooks.Title}
                tagName="h2"
                className={st(classes.title, classes.text)}
                type={TextType.Title}
              >
                {modalTitle}
              </Text>
            )}
            {modalSubtitle && (
              <div className={classes.runningText}>
                <Text
                  className={classes.text}
                  data-hook={ModalDataHooks.Subtitle}
                  type={TextType.Primary}
                >
                  {modalSubtitle}
                </Text>
              </div>
            )}
            <div className={st(classes.buttonContainer, { isMobile })}>
              {secondaryButton &&
                renderButton({
                  button: secondaryButton,
                  className: classes.secondary,
                  buttonDataHook: ModalDataHooks.SecondaryButton,
                })}
              {primaryButton &&
                renderButton({
                  button: primaryButton,
                  className: classes.primary,
                  buttonDataHook: ModalDataHooks.PrimaryButton,
                })}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
