import { ExtendedBooking } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { SlotService } from '../state/types';

export function formatBookingTime(
  slotService: SlotService,
  existingBookingDetails: ExtendedBooking,
  dateRegionalSettingsLocale: string,
) {
  const timezone =
    slotService.service.type === ServiceType.CLASS
      ? slotService.nestedSlot.timezone
      : undefined;

  const classStartTime =
    existingBookingDetails.booking?.bookedEntity?.slot?.startDate;
  const courseStartTime =
    existingBookingDetails.booking?.bookedEntity?.schedule?.firstSessionStart!;

  const timeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale,
    timezone,
  );

  const startTime = timeFormatter.formatTime(classStartTime || courseStartTime);
  return startTime;
}
